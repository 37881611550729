import * as React from "react";
import Button from "@mui/joy/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useSelector } from "react-redux";
import { updateQuoteById, submitQuoteById } from "../../utils/api/quote";
import { useParams } from "react-router-dom";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
import Input from "@mui/joy/Input";
import Textarea from "@mui/joy/Textarea";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import { updateFields } from "../../utils/globalSlice";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { sendEmail } from "../../utils/firebaseFunctions";
import { useDispatch } from "react-redux";
import {
  getFeatureLength,
  getGatePrice,
  getTotalQuotePrice,
  getAllFeatureLengths,
} from "../../utils/helperFunctions";
import { updateField } from "../../utils/customerSlice";

import dayjs from "dayjs";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
  deleteAll,
  totalPrice,
  draw,
  disabled,
}) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const customer = useSelector((state) => state.customer);

  const company = useSelector((state) => state.company);
  const features = useSelector((state) => state.customer.features);
  const gates = useSelector((state) => state.customer.gates);
  const catalogGateMap = company ? company.catalogGateMap : {};
  const featuresFenceType = useSelector(
    (state) => state.customer.featuresFenceType
  );
  const catalogMap = company ? company.catalogMap : null;
  console.log("customer: ", customer);
  const { quoteId } = useParams();
  const dispatch = useDispatch();

  const notesChangeHandler = (e) => {
    dispatch(
      dispatch(
        updateField({
          field: "details",
          value: { ...customer.details, notes: e.target.value },
        })
      )
    );
  };

  const dateOnChangeHandler = (date) => {
    const selectedDateString = new Date(date.$d).toISOString();

    dispatch(
      updateField({
        field: "details",
        value: {
          ...customer.details,
          desiredInstallationDate: selectedDateString,
        },
      })
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // deleteAll();
    setOpen(false);
  };

  const sendRequestHandler = async () => {
    sendEmail({
      toEmail: customer.details.email,
      subject: "Your Fence Quote",
      text: "Thanks for using our fence quote tool!",
      emailContent: `
      <!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Fence Quote from ${company.companyName}</title>
<style>
body {
font-family: Arial, sans-serif;
background-color: #f7f7f7;
color: #333;
margin: 0;
padding: 0;
}
.container {
max-width: 600px;
margin: 0 auto;
background-color: #ffffff;
border-radius: 8px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
overflow: hidden;
}
.header {
background-color: #007B8F;
padding: 20px;
text-align: center;
}
.header img {
max-width: 150px;
border-radius: 50%;
}
.content {
padding: 20px;
text-align: center;
}
.content h2 {
color: #007B8F;
}
.content p {
font-size: 16px;
line-height: 1.5;
}
.button {
display: inline-block;
background-color: #007B8F;
color: #ffffff !important; /* Ensure white text */
text-decoration: none;
padding: 12px 24px;
border-radius: 5px;
margin-top: 20px;
font-weight: bold;
}
.button:hover {
background-color: #005F6B;
}
.footer {
background-color: #f1f1f1;
padding: 20px;
text-align: center;
font-size: 14px;
color: #666;
}
.footer a {
color: #007B8F;
text-decoration: none;
}
</style>
</head>
<body>
<div class="container">
<!-- Header Section -->
<div class="header">
<img src="
${company.companyLogo}
" 
alt="${company.companyName} Logo"
>
<h1>${company.companyName}</h1>
</div>

<!-- Content Section -->
<div class="content">
<h2>Thank You ${customer.details.name}, for using our fence quoting Tool!</h2>
<p>We appreciate your interest in ${company.companyName}</p>
      <p>We will be in touch with you shortly.</p>
      <p> Please click the link below to view your custom fence quote:</p>
<a href="https://pro.fencelink.app/fence-map/${quoteId}/${encodeURIComponent(
        customer.details.address
      )}" class="button" style="color: #ffffff !important;">View Your Quote</a>

    ${
      customer.details.notes
        ? `<p><strong>Your Notes:</strong> ${customer.details.notes}</p>`
        : ""
    }
    ${
      customer.details.desiredInstallationDate
        ? `      <p><strong>Desired Installation Date:</strong> ${new Date(
            customer.details.desiredInstallationDate
          ).toLocaleDateString()}</p>`
        : ""
    }

</div>

<!-- Footer Section -->
<div class="footer">
<p><strong>Contact Us:</strong></p>
<p>
${
  company.companyAddress &&
  `<strong>Address:</strong>${company.companyAddress}<br>`
}

      ${
        company.companyPhone &&
        `<strong>Phone:</strong> <a href="tel:${company.companyPhone}">${company.companyPhone}</a><br>`
      }

      ${
        company.companyEmail &&
        ` <strong>Email:</strong> <a href="mailto:${company.companyEmail}">${company.companyEmail}</a><br>`
      }
${
  company.companyWebsite &&
  `<strong>Website:</strong> <a href="${company.companyWebsite}">${company.companyWebsite}</a>`
}
</p>
</div>
</div>
</body>
</html>
      `,
      fromName: company.companyName,
    });

    sendEmail({
      toEmail: company.companyEmail,
      subject: `${customer.details.name} - Fence Quote Request`,
      text: `New request from ${customer.details.name}`,
      emailContent: `
                              <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>New Quote Request for ${company.companyName}</title>
        <style>
            body {
                font-family: Arial, sans-serif;
                background-color: #f7f7f7;
                color: #333;
                margin: 0;
                padding: 0;
            }
            .container {
                max-width: 600px;
                margin: 0 auto;
                background-color: #ffffff;
                border-radius: 8px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                overflow: hidden;
            }
            .header {
                background-color: #007B8F;
                padding: 20px;
                text-align: center;
            }
            .header img {
                max-width: 150px;
                border-radius: 50%;
            }
            .content {
                padding: 20px;
                text-align: center;
            }
            .content h2 {
                color: #007B8F;
            }
            .content p {
                font-size: 16px;
                line-height: 1.5;
            }
            .button {
                display: inline-block;
                background-color: #007B8F;
                color: #ffffff !important; /* Ensure white text */
                text-decoration: none;
                padding: 12px 24px;
                border-radius: 5px;
                margin-top: 20px;
                font-weight: bold;
            }
            .button:hover {
                background-color: #005F6B;
            }
            .footer {
                background-color: #f1f1f1;
                padding: 20px;
                text-align: center;
                font-size: 14px;
                color: #666;
            }
            .footer a {
                color: #007B8F;
                text-decoration: none;
            }
        </style>
    </head>
    <body>
        <div class="container">
            <!-- Header Section -->
            <div class="header">
                <img src="
                ${company.companyLogo}
                "
                alt="${company.companyName} Logo"
                >
                <h1>${company.companyName}</h1>
            </div>

            <!-- Content Section -->
            <div class="content">
                <h2>New Quote Request</h2>
                <p>We have  a request from  ${customer.details.name}. 
                Here is the link to their drawing:</p>
                <a href="https://pro.fencelink.app/fence-map/${quoteId}/${encodeURIComponent(
        customer.details.address
      )}" class="button" style="color: #ffffff !important;">View their quote drawing</a>
            </div>

                <div>
                <h2>Contact Information</h2>
                <p><strong>Name:</strong> ${customer.details.name}</p>
                <p><strong>Email:</strong> <a href="mailto:${
                  customer.details.email
                }">${customer.details.email}</a></p>
                <p><strong>Phone:</strong> <a href="tel:${
                  customer.details.phone
                }">${customer.details.phone}</a></p>
                <p><strong>Address:</strong> ${customer.details.address}</p>

                ${
                  customer.details.notes
                    ? `<p><strong>Customer Notes:</strong> ${customer.details.notes}</p>`
                    : ""
                }
                ${
                  customer.details.desiredInstallationDate
                    ? `<p><strong>Desired Installation Date:</strong> ${new Date(
                        customer.details.desiredInstallationDate
                      ).toLocaleDateString()}</p>`
                    : ""
                }
                

                
                </div>

            <!-- Footer Section -->
            <div class="footer">
                <p><strong>Contact Us:</strong></p>
                <p>
                ${
                  company.companyAddress &&
                  `<strong>Address:</strong>${company.companyAddress}<br>`
                }

                              ${
                                company.companyPhone &&
                                `<strong>Phone:</strong> <a href="tel:${company.companyPhone}">${company.companyPhone}</a><br>`
                              }

                              ${
                                company.companyEmail &&
                                ` <strong>Email:</strong> <a href="mailto:${company.companyEmail}">${company.companyEmail}</a><br>`
                              }
                    ${
                      company.companyWebsite &&
                      `<strong>Website:</strong> <a href="${company.companyWebsite}">${company.companyWebsite}</a>`
                    }
                </p>
            </div>
        </div>
    </body>
    </html>
                              `,
      fromName: "FenceLink",
    });

    dispatch(
      updateFields({
        snackbarText: "Email Sent!",
        snackbarOpen: true,
        snackbarColor: "success",
      })
    );

    const allFeatures = draw.getAll();

    const mapboxFeatures = allFeatures.features;

    const transformedFeatures = mapboxFeatures.map((feature) => {
      const transformedFeature = {
        ...feature,
        geometry: {
          ...feature.geometry,
          coordinates: feature.geometry.coordinates.map((coord) => ({
            lat: coord[1],
            lng: coord[0],
          })),
        },
      };
      return transformedFeature;
    });

    const totalPrice = getTotalQuotePrice(
      features,
      featuresFenceType,
      catalogMap,
      gates,
      catalogGateMap
    );

    submitQuoteById(quoteId, {
      price: totalPrice || 0,
      notes: customer.details.notes || "",
      desiredInstallationDate: customer.details.desiredInstallationDate || "",
      data: {
        features: transformedFeatures,
        featuresFenceType: customer.featuresFenceType,
        gates: customer.gates,
      },
    });

    navigate(`/completed/${customer.details.id}`);
  };

  const handleSubmit = () => {
    handleClose();
  };

  return (
    <React.Fragment>
      <Button
        disabled={disabled}
        onClick={handleClickOpen}
        className="cta-button"
        variant="solid"
      >
        Review Quote
      </Button>

      <Modal
        aria-labelledby="submit quote"
        aria-describedby="submit quote"
        open={open}
        onClose={() => setOpen(false)}
        keepMounted
        sx={{
          zIndex: 3000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ModalDialog
          sx={{ overflowY: "scroll", backgroundColor: "#f5f5f5" }}
          layout="fullscreen"
        >
          {/* <Sheet
            variant="outlined"
            sx={{
              maxWidth: 800,
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
            }}
          > */}
          <ModalClose variant="plain" />
          <Stack
            gap={4}
            sx={{
              pb: 2,
              p: 2,
              alignItems: "center",
              width: "100%",
            }}
          >
            {customer.company.companyLogo && (
              <Box
                sx={{
                  // maxWidth: 200,
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  gap: 4,
                  alignItems: "center",
                }}
              >
                <Box>
                  <AspectRatio
                    ratio="1"
                    sx={{ flex: 1, minWidth: 120, borderRadius: "100%" }}
                  >
                    <img
                      src={customer.company.companyLogo}
                      loading="lazy"
                      alt={`${customer.company.companyLogo} logo`}
                    />
                  </AspectRatio>
                </Box>
                <Box>
                  <Typography component="h1" level="h3">
                    {customer.company.companyName}
                  </Typography>
                  <Typography level="body-sm">
                    Below is the breakdown of your quote!
                  </Typography>
                </Box>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 3,
              }}
            >
              <Stack sx={{ width: "100%" }} gap={1}>
                {customer.features.map((feature) => {
                  return (
                    customer.featuresFenceType[feature.id] && (
                      <Card
                        variant="outlined"
                        color="neutral"
                        sx={{
                          marginBottom: 2,
                          p: 4,
                        }}
                      >
                        <Box
                          sx={{
                            flexDirection: "row",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: 2,
                          }}
                        >
                          <img
                            style={{ width: "60px", height: "60px" }}
                            src={
                              // catalogMap[featuresFenceType[feature.id].style.id] &&
                              customer.featuresFenceType[feature.id].style
                                .imageUrl
                            }
                            alt={
                              // catalogMap[featuresFenceType[feature.id].style.id] &&
                              customer.featuresFenceType[feature.id].style.name
                            }
                            loading="lazy"
                          />

                          <Box>
                            <Box sx={{ display: "flex" }}>
                              <Typography
                                sx={{ paddingRight: 1 }}
                                fontWeight="lg"
                              >
                                Style:
                              </Typography>
                              <Typography>
                                {
                                  customer.featuresFenceType[feature.id].style
                                    .name
                                }
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex" }}>
                              <Typography
                                sx={{ paddingRight: 1 }}
                                fontWeight="lg"
                              >
                                Material:
                              </Typography>
                              <Typography>
                                {
                                  customer.featuresFenceType[feature.id]
                                    .material.name
                                }
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                sx={{ paddingRight: 1 }}
                                fontWeight="lg"
                              >
                                Attributes:
                              </Typography>
                              {Object.keys(
                                customer.featuresFenceType[feature.id]
                                  .attributesMap
                              ).map((attributeId) => {
                                return (
                                  <Box
                                    sx={{
                                      paddingLeft: 2,
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Typography
                                      sx={{ paddingRight: 1 }}
                                      fontWeight="lg"
                                    >
                                      {
                                        company.globalAttributesMap[
                                          customer.featuresFenceType[feature.id]
                                            .attributesMap[attributeId].id
                                        ].name
                                      }
                                      :
                                    </Typography>

                                    {
                                      customer.featuresFenceType[feature.id]
                                        .attributesMap[attributeId].item.name
                                    }
                                  </Box>
                                );
                              })}
                            </Box>
                          </Box>
                        </Box>

                        <Box>
                          {customer.featuresFenceType[feature.id].gates &&
                            customer.gates && (
                              <>
                                <Typography
                                  sx={{ paddingRight: 1 }}
                                  fontWeight="lg"
                                >
                                  Gates:
                                </Typography>
                                {customer.featuresFenceType[
                                  feature.id
                                ].gates.map((gateId) => {
                                  const gateIndex = customer.gates.findIndex(
                                    (gate) => gate.id === gateId
                                  );

                                  return (
                                    <Card
                                      sx={{
                                        margin: 2,
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <img
                                        style={{
                                          width: "60px",
                                          height: "60px",
                                        }}
                                        src={
                                          // catalogMap[featuresFenceType[feature.id].style.id] &&
                                          customer.gates[gateIndex].style
                                            .imageUrl
                                        }
                                        alt={
                                          // catalogMap[featuresFenceType[feature.id].style.id] &&
                                          customer.gates[gateIndex].style.name
                                        }
                                        loading="lazy"
                                      />
                                      <Box>
                                        <Box sx={{ display: "flex" }}>
                                          <Typography
                                            sx={{ paddingRight: 1 }}
                                            fontWeight="lg"
                                          >
                                            Style:
                                          </Typography>
                                          <Typography>
                                            {customer.gates[gateIndex] &&
                                              customer.gates[gateIndex].style
                                                .name}
                                          </Typography>
                                        </Box>

                                        <Box>
                                          {" "}
                                          <Box sx={{ display: "flex" }}>
                                            <Typography
                                              sx={{ paddingRight: 1 }}
                                              fontWeight="lg"
                                            >
                                              Material:
                                            </Typography>
                                            {customer.gates[gateIndex] &&
                                              customer.gates[gateIndex].material
                                                .name}
                                          </Box>
                                          {Object.keys(
                                            customer.gates[gateIndex]
                                              .attributesMap
                                          ).length > 0 && (
                                            <Typography
                                              sx={{ paddingRight: 1 }}
                                              fontWeight="lg"
                                            >
                                              Attributes:
                                            </Typography>
                                          )}
                                          <Box sx={{ paddingLeft: 2 }}>
                                            {" "}
                                            {customer.gates[gateIndex] &&
                                              Object.keys(
                                                customer.gates[gateIndex]
                                                  .attributesMap
                                              ).map((attributeId) => {
                                                const attribute =
                                                  customer.gates[gateIndex]
                                                    .attributesMap[attributeId];
                                                return (
                                                  <Box
                                                    sx={{
                                                      flexDirection: "row",
                                                      display: "flex",
                                                    }}
                                                  >
                                                    <Typography
                                                      sx={{ paddingRight: 1 }}
                                                      fontWeight="lg"
                                                    >
                                                      {
                                                        company
                                                          .globalGateAttributesMap[
                                                          attribute.id
                                                        ].name
                                                      }
                                                      :
                                                    </Typography>
                                                    {attribute.item.name}
                                                  </Box>
                                                );
                                              })}
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Card>
                                  );
                                })}
                              </>
                            )}
                        </Box>
                      </Card>
                    )
                  );
                })}
              </Stack>
              <Card
                variant="outlined"
                color="neutral"
                sx={{
                  marginBottom: 2,
                  p: 4,
                  display: "flex",
                  gap: 2,
                  height: "fit-content",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 18,
                    minWidth: 300,
                    textAlign: "center",
                    borderBottom: "2px solid #ccc",
                    flexDirection: "column",
                  }}
                  level="h2"
                >
                  Order Summary
                </Typography>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography level="title-sm">Customer:</Typography>
                  <Typography level="body-sm">
                    {customer.details.name}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography level="title-sm">Address:</Typography>
                  <Typography
                    level="body-sm"
                    sx={{
                      fontSize: 14,
                      padding: "0 5px",
                      maxWidth: 200,
                      textAlign: "right",
                    }}
                  >
                    {customer.details.address}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography level="title-sm">Total Fence Length:</Typography>
                  <Typography level="body-sm">
                    {getAllFeatureLengths(features)}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography level="title-sm">Number of Gates:</Typography>
                  <Typography level="body-sm">{gates.length}</Typography>
                </Box>

                <FormControl>
                  <FormLabel>Notes:</FormLabel>
                  <Textarea
                    onChange={notesChangeHandler}
                    placeholder="add notes to your quote"
                    minRows={2}
                    value={customer.details.notes}
                  />
                </FormControl>

                <Box>
                  <FormControl>
                    <FormLabel>Desired Installation Date:</FormLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        defaultValue={dayjs(
                          customer.details.desiredInstallationDate
                        )}
                        onChange={dateOnChangeHandler}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography level="title-md">Estimated Total:</Typography>
                  <Typography level="title-md">
                    $
                    {getTotalQuotePrice(
                      features,
                      featuresFenceType,
                      catalogMap,
                      gates,
                      catalogGateMap
                    )}
                  </Typography>
                </Box>

                <Button onClick={sendRequestHandler}>
                  Send Request to Company
                </Button>
              </Card>
            </Box>
          </Stack>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}

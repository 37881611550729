import React, { useEffect, useState, useRef } from "react";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import Box from "@mui/joy/Box";
import GlobalStyles from "@mui/joy/GlobalStyles";

import "./index.css";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import AspectRatio from "@mui/joy/AspectRatio";

import axios from "axios";
import { useDispatch } from "react-redux";
import { updateField } from "../../utils/customerSlice";
import { useParams } from "react-router-dom";
import { getCompanyById } from "../../utils/api/company";
import IconButton, { IconButtonProps } from "@mui/joy/IconButton";
import BadgeRoundedIcon from "@mui/icons-material/BadgeRounded";
import settings from "../../utils/settings.json";
import Stack from "@mui/joy/Stack";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import { createQuote } from "../../utils/api/quote";
import { sendEmail } from "../../utils/firebaseFunctions";
import { getQuoteById } from "../../utils/api/quote";
import FenceLinkLogo from "../../assets/icons/link-icon-512.png";
import Avatar from "@mui/joy/Avatar";

const MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_TOKEN;

function ColorSchemeToggle(props) {
  const { onClick, ...other } = props;
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => setMounted(true), []);

  return (
    <></>
    // <IconButton
    //   aria-label="toggle light/dark mode"
    //   size="sm"
    //   variant="outlined"
    //   disabled={!mounted}
    //   onClick={(event) => {
    //     setMode(mode === "light" ? "dark" : "light");
    //     onClick?.(event);
    //   }}
    //   {...other}
    // >
    //   {mode === "light" ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
    // </IconButton>
  );
}

const CustomerInfoPage = () => {
  const { quoteId } = useParams();

  const [company, setCompany] = useState(null);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [foundQuote, setFoundQuote] = useState(null);

  useEffect(() => {
    getQuoteById(quoteId)
      .then((quote) => {
        if (!quote) {
          navigate("/404");
        }
        setFoundQuote({ id: quoteId, ...quote });
        dispatch(
          updateField({
            field: "details",
            value: { id: quoteId, ...quote },
          })
        );
        getCompanyById(quote.companyId)
          .then((companyData) => {
            setCompany(companyData);
          })
          .catch((err) => console.log("err: ", err));
      })
      .catch((err) => console.log("Couldn't find quote: ", err));
  }, []);

  // const handleSubmit = () => {
  //   dispatch(updateField({ field: "address", value: address }));
  //   dispatch(updateField({ field: "name", value: name }));
  //   dispatch(updateField({ field: "email", value: email }));

  //   navigate(`/fence-map/${address}`);
  // };

  console.log("foundQuote: ", foundQuote);

  return (
    foundQuote &&
    company && (
      <>
        <GlobalStyles
          styles={{
            ":root": {
              "--Form-maxWidth": "800px",
              "--Transition-duration": "0.4s", // set to `none` to disable transition
            },
          }}
        />
        {/* <Box
  sx={(theme) => ({
    width: { xs: "100%", md: "50vw" },
    transition: "width var(--Transition-duration)",
    transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
    position: "relative",
    zIndex: 1,
    display: "flex",
    justifyContent: "flex-end",
    backdropFilter: "blur(12px)",
    backgroundColor: "rgba(255 255 255 / 0.2)",
    [theme.getColorSchemeSelector("light")]: {
      backgroundColor: "rgba(255, 255, 255 / 0.4)",
    },
  })}
> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100dvh",
            width: "100%",
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            {/* <Box sx={{ gap: 2, display: "flex", alignItems: "center" }}>
              <Typography level="body-sm">{company.companyName}</Typography>
            </Box> */}
            <ColorSchemeToggle />
          </Box>
          <Box
            component="main"
            sx={{
              my: "auto",
              py: 2,
              pb: 5,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: 400,
              maxWidth: "100%",
              mx: "auto",
              borderRadius: "sm",
              "& form": {
                display: "flex",
                flexDirection: "column",
                gap: 2,
              },
              [`& .MuiFormLabel-asterisk`]: {
                visibility: "hidden",
              },
            }}
          >
            <Stack gap={4} sx={{ mb: 2, alignItems: "center" }}>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  maxWidth: 200,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {company.companyLogo ? (
                  <AspectRatio
                    ratio="1"
                    // maxHeight={200}
                    sx={{ flex: 1, minWidth: 120, borderRadius: "100%" }}
                  >
                    <img src={company.companyLogo} loading="lazy" alt="" />
                  </AspectRatio>
                ) : (
                  <AspectRatio
                    ratio="1"
                    // maxHeight={200}
                    sx={{ flex: 1, minWidth: 120, borderRadius: "100%" }}
                  >
                    <Avatar>
                      {company.companyName
                        .toUpperCase()
                        .split(" ")
                        .map((word, index) => {
                          if (index < 2) {
                            return word[0];
                          } else return "";
                        })
                        .join("")}
                    </Avatar>
                  </AspectRatio>
                )}
                <Typography component="h1" level="h3">
                  &
                </Typography>
                <AspectRatio
                  ratio="1"
                  // maxHeight={200}
                  sx={{ flex: 1, minWidth: 120, borderRadius: "100%" }}
                >
                  <img src={FenceLinkLogo} loading="lazy" alt="" />
                </AspectRatio>
              </Stack>

              <Stack gap={1}>
                <Typography component="h1" level="h3">
                  {company.companyName}
                </Typography>
                <Typography level="body-sm">
                  Thank you for using FenceLink
                </Typography>
                <Typography level="body-sm">
                  You may close this window or return to our website{" "}
                  <a href={company.companyWebsite}>{company.companyWebsite}</a>
                </Typography>
              </Stack>
            </Stack>
          </Box>
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-xs" textAlign="center">
              © {settings.companyName} {new Date().getFullYear()}
            </Typography>
          </Box>
          {/* </Box> */}
        </Box>
        {/* <Box
  sx={(theme) => ({
    height: "100%",
    position: "fixed",
    right: 0,
    top: 0,
    bottom: 0,
    left: { xs: 0, md: "50vw" },
    transition:
      "background-image var(--Transition-duration), left var(--Transition-duration) !important",
    transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
    backgroundColor: "background.level1",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundImage:
      "url(https://images.unsplash.com/photo-1533803786316-b85d29b59a7f?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)",
    [theme.getColorSchemeSelector("light")]: {
      backgroundImage:
        "url(https://images.unsplash.com/photo-1533803786316-b85d29b59a7f?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)",
    },
  })}
/> */}
      </>
    )
  );
};

export default CustomerInfoPage;

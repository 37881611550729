import React, { useEffect, useState } from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";

import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import LinkIcon from "@mui/icons-material/Link";

// import Sidebar from "./Sidebar";
import CatalogTable from "./CatalogTable";
import CatalogList from "./CatalogList";
// import Header from "./Header";
import { getQuotesByCompanyId } from "../../utils/api/quote";
import { getUserCompanyId } from "../../utils/api/user";
import { getCompanyById } from "../../utils/api/company";
import { useSelector, useDispatch } from "react-redux";

import * as globalFenceData from "../../utils/globalFenceData";
import { timestampToISO } from "../../utils/helperFunctions";
import { getAllCollection } from "../../utils/api/options";
import {
  setCompanyData,
  setGlobalOptions,
  setGlobalTypes,
  setGlobalData,
} from "../../utils/companySlice";

export default function SetupPage() {
  // const [quotes, setQuotes] = useState([]);
  // const [companyId, setCompanyId] = useState(null);
  // const [company, setCompany] = useState({});
  const [copyQuote, setCopyQuote] = useState("");
  const dispatch = useDispatch();
  const company = useSelector((state) => state.company);
  const quotes = company.quotes;

  const handleClickCopyQuote = () => {
    setCopyQuote("Copied!");

    setTimeout(() => setCopyQuote("Copy Link For Customer"), 3000);
  };

  return (
    <Box sx={{ display: "flex", minHeight: "100dvh" }}>
      {/* <Header />
        <Sidebar /> */}
      <Box
        component="main"
        className="MainContent"
        sx={{
          px: { xs: 2, md: 6 },
          pt: {
            xs: "calc(12px + var(--Header-height))",
            sm: "calc(12px + var(--Header-height))",
            md: 3,
          },
          pb: { xs: 2, sm: 2, md: 3 },
          flex: 1,
          display: "flex",
          flexDirection: "column",
          minWidth: 0,
          height: "100dvh",
          gap: 1,
        }}
      >
        <CatalogTable />
        {/* <CatalogList globalFenceData={globalFenceData} /> */}
      </Box>
    </Box>
  );
}

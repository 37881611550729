import React, { useEffect, useState } from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";

import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import LinkIcon from "@mui/icons-material/Link";

// import Sidebar from "./Sidebar";
import OrderTable from "./OrderTable";
import OrderList from "./OrderList";
// import Header from "./Header";
import { getQuotesByCompanyId } from "../../utils/api/quote";
import { getUserCompanyId } from "../../utils/api/user";
import { getCompanyById } from "../../utils/api/company";
import { useSelector } from "react-redux";

export default function QuoteDashboard() {
  // const [quotes, setQuotes] = useState([]);
  // const [companyId, setCompanyId] = useState(null);
  // const [company, setCompany] = useState({});
  const [copyQuote, setCopyQuote] = useState("");

  const company = useSelector((state) => state.company);
  const quotes = company.quotes;

  const handleClickCopyQuote = () => {
    setCopyQuote("Copied!");

    setTimeout(() => setCopyQuote("Copy Link For Customer"), 3000);
  };

  return (
    <Box sx={{ display: "flex", minHeight: "100dvh" }}>
      {/* <Header />
        <Sidebar /> */}
      <Box
        component="main"
        className="MainContent"
        sx={{
          px: { xs: 2, md: 6 },
          pt: {
            xs: "calc(12px + var(--Header-height))",
            sm: "calc(12px + var(--Header-height))",
            md: 3,
          },
          pb: { xs: 2, sm: 2, md: 3 },
          flex: 1,
          display: "flex",
          flexDirection: "column",
          minWidth: 0,
          height: "100dvh",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            mb: 1,
            gap: 1,
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "start", sm: "center" },
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Button
            color="primary"
            startDecorator={<DownloadRoundedIcon />}
            size="sm"
          >
            Download PDF
          </Button>
          <Button
            color="primary"
            startDecorator={<LinkIcon />}
            size="sm"
            onClick={(e) => {
              navigator.clipboard
                .writeText(`${window.location.origin}/quote/${company.id}`)
                .then((res) => handleClickCopyQuote())
                .catch((err) =>
                  console.log("error is copying quote link: ", err)
                );
            }}
          >
            {copyQuote ? copyQuote : "Copy Link For Customer"}
          </Button>
        </Box>
        <OrderTable rows={quotes} />
        <OrderList listItems={quotes} />
      </Box>
    </Box>
  );
}

import * as React from "react";
import GlobalStyles from "@mui/joy/GlobalStyles";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Checkbox from "@mui/joy/Checkbox";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import IconButton, { IconButtonProps } from "@mui/joy/IconButton";
import Link from "@mui/joy/Link";
import Input from "@mui/joy/Input";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import BadgeRoundedIcon from "@mui/icons-material/BadgeRounded";
import { useNavigate } from "react-router-dom";
import settings from "../../utils/settings.json";
import { auth } from "../../utils/FireStore"; // Import the auth instance
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import linkIcon from "../../assets/icons/link-icon-32.png";
import FenceLinkNameLogo from "../../assets/icons/name-logo/Component 1.svg";

function ColorSchemeToggle(props) {
  const { onClick, ...other } = props;
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => setMounted(true), []);

  return (
    <></>
    // <IconButton
    //   aria-label="toggle light/dark mode"
    //   size="sm"
    //   variant="outlined"
    //   disabled={!mounted}
    //   onClick={(event) => {
    //     setMode(mode === "light" ? "dark" : "light");
    //     onClick?.(event);
    //   }}
    //   {...other}
    // >
    //   {mode === "light" ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
    // </IconButton>
  );
}

export default function ForgotPasswordPage() {
  const navigate = useNavigate();
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState("");

  return (
    <>
      <GlobalStyles
        styles={{
          ":root": {
            "--Form-maxWidth": "800px",
            "--Transition-duration": "0.4s", // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={(theme) => ({
          width: { xs: "100%", md: "50vw" },
          transition: "width var(--Transition-duration)",
          transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
          position: "relative",
          zIndex: 1,
          display: "flex",
          justifyContent: "flex-end",
          backdropFilter: "blur(12px)",
          backgroundColor: "rgba(255 255 255 / 0.2)",
          [theme.getColorSchemeSelector("light")]: {
            backgroundColor: "rgba(255, 255, 255 / 0.4)",
          },
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100dvh",
            width: "100%",
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ gap: 2, display: "flex", alignItems: "center" }}>
              <img width={160} src={FenceLinkNameLogo} alt="FenceLink icon" />
            </Box>
            <ColorSchemeToggle />
          </Box>
          <Box
            component="main"
            sx={{
              my: "auto",
              py: 2,
              pb: 5,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: 400,
              maxWidth: "100%",
              mx: "auto",
              borderRadius: "sm",
              "& form": {
                display: "flex",
                flexDirection: "column",
                gap: 2,
              },
              [`& .MuiFormLabel-asterisk`]: {
                visibility: "hidden",
              },
            }}
          >
            <Stack gap={4} sx={{ mb: 2 }}>
              <Stack gap={1}>
                <Typography component="h1" level="h3">
                  Having Password Issues?
                </Typography>
                <Typography level="body-sm">
                  Let's get you back into {settings.companyName}!{" "}
                </Typography>
              </Stack>
            </Stack>
            <Stack gap={4} sx={{ mt: 2 }}>
              <form
                onSubmit={async (event) => {
                  event.preventDefault();
                  const formElements = event.currentTarget.elements;
                  const data = {
                    email: formElements.email.value,
                  };

                  try {
                    setMessage("");
                    setError("");
                    await sendPasswordResetEmail(auth, data.email);
                    setMessage("Password reset email sent! Check your inbox.");
                  } catch (error) {
                    setError(
                      "Error sending password reset email. Please try again."
                    );
                    console.error("Error resetting password:", error);
                    console.error("Error logging in:", error);
                  }
                }}
              >
                <FormControl required>
                  <FormLabel>Email</FormLabel>
                  <Input type="email" name="email" />
                </FormControl>
                <Stack gap={4} sx={{ mt: 2 }}>
                  <Button type="submit" fullWidth>
                    Send Recovery Email
                  </Button>
                </Stack>
                <Link level="title-sm" onClick={() => navigate("/sign-in")}>
                  Return to sign in
                </Link>
              </form>
              {message && <p>{message}</p>}
              {error && <p>{error}</p>}
            </Stack>
          </Box>
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-xs" textAlign="center">
              © {settings.companyName} {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: "100%",
          position: "fixed",
          right: 0,
          top: 0,
          bottom: 0,
          left: { xs: 0, md: "50vw" },
          transition:
            "background-image var(--Transition-duration), left var(--Transition-duration) !important",
          transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
          backgroundColor: "background.level1",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage:
            "url(https://images.unsplash.com/photo-1533803786316-b85d29b59a7f?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)",
          [theme.getColorSchemeSelector("light")]: {
            backgroundImage:
              "url(https://images.unsplash.com/photo-1533803786316-b85d29b59a7f?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)",
          },
        })}
      />
    </>
  );
}
